import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect, OnInitEffects } from '@ngrx/effects';
import { mergeMap, map, withLatestFrom, filter, skipWhile, tap, catchError, switchMap } from 'rxjs/operators';
import { Store, select, Action } from '@ngrx/store';

import { AppState } from '../../core/core.state';
import { SettingsService } from '../services/settings.service';
import { currentOrganization } from '../../core/auth';
import { of, combineLatest, empty } from 'rxjs';
import * as SActions from './settings.actions';
import * as PropertyActions from '../../core/store/properties.actions';
import * as ToastrActions  from '../../core/store/toastr.actions';
import * as AuthActions  from '../../core/auth/auth.actions';


import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable()
export class SettingsEffects implements OnInitEffects  {

    loadSettings = createEffect(() => this.actions$.pipe(
        ofType(SActions.AllSettingsRequested),
        mergeMap(action => {
                return combineLatest(
                of(action),
                this.store.pipe(select(currentOrganization))
                )
            }
        ),
        skipWhile( ([action, org]) => ( org == null) ),
        mergeMap( ([action, org]) => 
                this.settingsService.getSettings( org.id )
        ),
        switchMap( (s) => 
            [
                SActions.AllSettingsLoaded({settings: s.model}),
                PropertyActions.AllPropertiesLoaded({properties: s.model.properties})
            ]
        )
    ));



    /**
     * Member Effects
     */
    saveMember = createEffect(() => this.actions$.pipe(
        ofType(SActions.MemberSave),
        withLatestFrom(this.store.pipe(select(currentOrganization))),
        mergeMap( ([action, org]) => {
            return this.settingsService.addMember(action.user, org.id).pipe(
                switchMap(res => {
                    let m = (action.user.id) ? 
                                `${res.model.fullname} was successfully changed` : 
                                `${res.model.fullname} was successfully added to your organization`
                    return [ 
                        SActions.MemberSaveSuccess({user: res.model, message: m}),
                        /* Update the settins, which includes the properties, to make
                        sure we have the updated list of assigned users properties */
                        SActions.AllSettingsRequested(),
                        ToastrActions.showToastrSuccess({message: m, title: ''})
                    ];
                }),
                catchError((error: HttpErrorResponse) => of(
                    SActions.MemberSaveFailed({message: error.error}),
                    ToastrActions.showToastrError({message: error.error , title: 'Something went wrong'})
                ))           
            );
        })
    ));


    deleteMember = createEffect(() => this.actions$.pipe(
        ofType(SActions.MemberDelete),
        withLatestFrom(this.store.pipe(select(currentOrganization))),
        mergeMap( ([action, org]) => {
            return this.settingsService.deleteMember(action.member_id, org.id).pipe(
                switchMap(res => [
                    SActions.MemberDeleteSuccess({member_id: action.member_id}),
                    ToastrActions.showToastrSuccess( {message:'Member was successfully removed from your team.', title: ''}),
                    /* We need here to update the entire settings, since some field like the
                    notification_transcript relies on the user id, so we need to clean those */
                    SActions.AllSettingsRequested()
                ]),
                catchError((error) => of(
                    // @todo create our own MemberDeleteFailed
                    SActions.MemberSaveFailed({message: error.error}),
                    ToastrActions.showToastrError({message: error.error , title: 'Something went wrong'})
                ))
            );
        }) 
    ));

    saveNotifications = createEffect(() => this.actions$.pipe(
        ofType(SActions.NotificationsSaveRequest),
        withLatestFrom(this.store.pipe(select(currentOrganization))),
        mergeMap( ([action, org]) => {
            return this.settingsService.saveNotifications(action.settings, org.id).pipe(
                switchMap(res => [
                    SActions.NotificationsSaveSuccess({settings: action.settings}),
                    ToastrActions.showToastrSuccess( {message:'Notifications were successfully saved.', title: ''}),
                ]),
                catchError((error) => of(
                    SActions.NotificationsSaveFailed({message: error.error}),
                    ToastrActions.showToastrError({message: error.error , title: 'Something went wrong'})
                ))            
            );
        })
    ));

    saveTags = createEffect(() => this.actions$.pipe(
        ofType(SActions.TagsSaveRequest),
        withLatestFrom(this.store.pipe(select(currentOrganization))),
        mergeMap( ([action, org]) => {
            return this.settingsService.saveTags(action.tags, org.id).pipe(
                switchMap(res => [
                    SActions.TagsSaveSuccess({tags: res.model}),
                    ToastrActions.showToastrSuccess( {message:'Tags were successfully saved.', title: ''}),
                ]),
                catchError((error) => of(
                    SActions.TagsSaveFailed({message: error.error}),
                    ToastrActions.showToastrError({message: error.error , title: 'Something went wrong'})
                ))            
            );
        })
    ));

    saveOrg = createEffect(() => this.actions$.pipe(
        ofType(SActions.OrgSaveRequest),
        mergeMap( action => {
            return this.settingsService.saveOrg(action.org).pipe(
                switchMap(res => [
                    SActions.OrgSaveSuccess({org: res.model}),
                    AuthActions.OrgUpdate({org: res.model}),
                    ToastrActions.showToastrSuccess( {message:'Organization was successfully saved.', title: ''}),
                ]),
                catchError((error) => of(
                    SActions.OrgSaveFailed({message: error.error}),
                    ToastrActions.showToastrError({message: error.error , title: 'Something went wrong'})
                ))            
            );
        })
    ));

    saveTicketsTags = createEffect(() => this.actions$.pipe(
        ofType(SActions.TicketsTagsSaveRequest),
        withLatestFrom(this.store.pipe(select(currentOrganization))),
        mergeMap( ([action, org]) => {
            return this.settingsService.saveTicketsTags(action.tags, org.id).pipe(
                switchMap(res => [
                    SActions.TicketsTagsSaveSuccess({tags: res.model}),
                    ToastrActions.showToastrSuccess( {message:'Tags were successfully saved.', title: ''}),
                ]),
                catchError((error) => of(
                    SActions.TicketsTagsSaveFailed({message: error.error}),
                    ToastrActions.showToastrError({message: error.error , title: 'Something went wrong'})
                ))            
            );
        })
    ));
    
    ngrxOnInitEffects(): Action {
        return { type: SActions.AllSettingsRequested.type };
    }

    
    constructor(
        private settingsService: SettingsService,
        private actions$: Actions,
        private store: Store<AppState>,
        private toastr: ToastrService
    ) { 
    }
    
}
    