import { HttpClient, HttpEvent } from '@angular/common/http';
import {  Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { combineLatest, interval, Observable, of, Subject, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { currentOrganization, currentUser } from '../auth';
import { AppState } from '../core.state';
import { ActivitiesResponse, ActivityResponse, User, VisitResponse } from '../../models';
import { Message, MessageResponse, Messages } from '../../models/message.model';
import { Visitor, VisitorResponse, Visitors } from '../../models/visitor.model';
import * as ActivityActions from '../../conversations/store/activity.actions';
import { takeUntil, filter, take, takeWhile } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { VisitorTyping } from '../../conversations/store/visitors.actions';
import * as ToastrActions  from '../store/toastr.actions';
import * as TicketsActions from '../../tickets/store/tickets.actions';
import * as VisitorActions from '../../conversations/store/visitors.actions';

import { Update } from '@ngrx/entity';
import { HubDisconnected } from '../store/layout.actions';
import { selectVisitorById } from 'src/app/conversations/store/visitors.selectors';


const API_URL = environment.apiUrl;

@Injectable({
	providedIn: 'root'
})
export class ConversationsService {

	private hub: HubConnection | undefined;
	notify_sound: boolean = false;
	notify_popup: boolean = false;
	notify_flash: boolean = false;
	keep_ringing: boolean = false;
	agent_id: string;
	audio: HTMLAudioElement;

	sounds = ['/assets/media/new-message.wav',
		'/assets/media/sound-2.mp3',
		'/assets/media/sound-3.mp3',
		'/assets/media/sound-4.mp3',
		'/assets/media/sound-5.mp3',
		'/assets/media/sound-6.mp3',
	];

	constructor(
		private http: HttpClient,
		private store: Store<AppState>,
		private titleService: Title
	) {
		this.startHub();

		this.store.select(currentUser).pipe(
				filter(u => u !== undefined )
			).subscribe(u => {

				this.notify_sound = u.notify_sound;
				this.notify_popup = u.notify_popup;
				this.notify_flash = u.notify_flash;
				this.agent_id = u.id;
				this.keep_ringing = u.keep_ringing;
				this.audio = new Audio(this.sounds[u.sound - 1]);
			});


	}



	// @todo Implement email session
	public emailSession(session_id: string, visitor_id): Observable < MessageResponse > {
		return this.http.put < MessageResponse > (`${API_URL}/api/activities/emailsession`, ({
			session_id: session_id,
			visitor_id: visitor_id
		}));
	}

	public closeSession(visitor_id: string): Observable < VisitorResponse > {
		return this.http.put < VisitorResponse > (`${API_URL}/api/activities/closesession`, ({
			id: visitor_id
		}));
	}

	public deleteSessions(visitor_id: string): Observable < VisitorResponse > {
		return this.http.put < VisitorResponse > (`${API_URL}/api/activities/deletesessions`, ({
			id: visitor_id
		}));
	}

	public getMessage(message_id: string): Observable < MessageResponse > {
		return this.http.get < MessageResponse > (`${API_URL}/api/messages/${message_id}`);
	}

	public getMessages(visitor_id: string): Observable < Messages > {
		return this.http.get < Messages > (`${API_URL}/api/messages?visitor=${visitor_id}`);
	}

	public getVisitor(visitor_id: string): Observable < VisitorResponse > {
		return this.http.get < VisitorResponse > (`${API_URL}/api/visitors/${visitor_id}`);
	}

	public getVisitors(property_id: string, view_mode:string, agent_id = '', page: number): Observable < Visitors > {
		
		let is_closed = (view_mode == 'closed') ? true : false;
		let agent = (view_mode == 'agent') ? agent_id : ''; 
		
		return this.http.get<Visitors>(
				`${API_URL}/api/visitors?property_ids=${property_id}
				&agent_id=${agent}
				&is_closed=${is_closed}
				&page=${page}
				&limit=${environment.recent_conversations_pagesize}`
			);
	}

	public getVisit(activity_id: number): Observable < VisitResponse > {
		return this.http.get < VisitResponse > (`${API_URL}/api/visits/${activity_id}`);
	}

	public getActivities(visitor_id: string): Observable < ActivitiesResponse > {
		return this.http.get < ActivitiesResponse > (`${API_URL}/api/activities?visitor=${visitor_id}`);
	}

	public saveVisitorInfo(id: string | number, visitor: Partial < Visitor > ): Observable < VisitorResponse > {
		return this.http.put < VisitorResponse > (`${API_URL}/api/visitors/${id}`, visitor);
	}

	public uploadAttachment(file: FormData): Observable<HttpEvent<MessageResponse>> {
		return this.http.post<MessageResponse>(`${API_URL}/api/messages/uploadfile`, file, { reportProgress: true, observe: 'events' }); 
	  }

	send(message: Message) {
		if (this.hub) {
			this.hub.invoke('SendtoVisitor', message);
		}
		return of(message);
	}

	joinGroup(group: string): void {
		if (this.hub) {
			this.hub.invoke('JoinGroup', group);
		}
	}

	leaveGroup(group: string): void {
		if (this.hub) {
			this.hub.invoke('LeaveGroup', group);
		}
	}

	getAllGroups(): Observable < string[] > {
		//return this.http.get<string[]>(this.actionUrl, { headers: this.headers });
		return of([]);
	}

	public startHub() {

		this.hub = new signalR.HubConnectionBuilder()
			.configureLogging(signalR.LogLevel.Information)
			.withAutomaticReconnect()
			.withUrl(`${environment.apiUrl}/hub`)
			.build();

		this.hub.start()
			.then(() => {
				console.log("Connection Established");

				this.store.select(currentOrganization).subscribe(org => {
					if (org == null) return;

					console.log("Registering Agent...");
					this.hub.invoke("AgentConnected", org.id)
				});

			})
			.catch(err =>
				console.error(err.toString())
			);


		this.hub.on("ackMessage", (id: string) => {
			this.store.dispatch(ActivityActions.MessageActivityReceived({
				message_id: id
			}));
			console.log('Acknowledge recieved: ' + id);
		});

		this.hub.on("SendtoVisitorFailed", (message: string, message_id: string) => {
			this.store.dispatch(ActivityActions.SendMessageFailed({
				error: message, message_id: message_id
			}));
		});

		this.hub.on("messageReceived", (id: string) => {
			this.store.dispatch(ActivityActions.MessageActivityReceived({
				message_id: id
			}));
		});

		this.hub.on("visitReceived", (id: number) => {
			this.store.dispatch(ActivityActions.VisitRequested({
				id: id
			}));
		});

		this.hub.on("newTicket", (id: number) => {
			this.store.dispatch(TicketsActions.NewTicketReceived({
				ticket_id: id
			}));
		});

		this.hub.on("newTicketReply", (id: number) => {
			this.store.dispatch(TicketsActions.NewReplyReceived({
				ticket_id: id
			}));
		});

		this.hub.on("newSession", (id: string, created_at: string, visitor_id: string) => {
			this.store.dispatch(
				ActivityActions.NewSession({
					id: id,
					created_at: created_at,
					visitor_id: visitor_id
				})
			);
		});

		this.hub.on("messageFromAgent", (id: string, agent: string) => {
				
			this.store.dispatch(ActivityActions.AgentActivityReceived({
				message_id: id
			}));
		});

		this.hub.on("VisitorTyping", (visitor_id: string, is_typing: boolean) => {
			this.store.dispatch(VisitorTyping({
				visitor_id: visitor_id,
				is_typing: is_typing
			}))
		});

		this.hub.on("MessageReadByVisitor", (message_id: string ) => {
			this.store.dispatch(ActivityActions.MessageReadByVisitorReceived({
				message_id: message_id
			}))
			console.debug("Message read by visitor");
		});

		this.hub.on("updateVisitorAttributes", (visitor: Visitor) => {
			console.log(`Updated Visitor - ${visitor.email} - ${visitor.name}`);
		
			var updates = {};
			if (visitor.email) updates["email"] = visitor.email;
			if (visitor.phone) updates["phone"] = visitor.phone;
			if (visitor.name) updates["name"] = visitor.name;

			let v: Update<Visitor> = {
				id: visitor.id,
				changes: updates
			};
			this.store.dispatch(VisitorActions.VisitorUpdateSuccess({visitor: v}));
		
		});

		this.hub.onclose(error => {
			this.store.dispatch(HubDisconnected({is_disconnected: true}));
		});

		this.hub.onreconnected(error => {
			this.store.dispatch(HubDisconnected({is_disconnected: false}));
		});

	}

	reconnect(){

		this.hub.start()
			.then(() => {
				console.log("Connection Established");
				this.store.dispatch(HubDisconnected({is_disconnected: false}));
				this.store.select(currentOrganization).subscribe(org => {
					if (org == null) return;
					console.log("Registering Agent...");
					this.hub.invoke("AgentConnected", org.id)
				});
			})
			.catch(err =>
				console.error(err.toString())
			);		
	}

	show_notification( message: string, title: string, force: boolean = false ) {

		if ( (!force) && (!this.notify_popup) ) return;

		if (Notification.permission === "granted") {

			let notification = new Notification(title, {body: message });

		} else if (Notification.permission === "default") {

			Notification.requestPermission(function (permission) {
				// If the user accepts, let's create a notification
				if (permission === "granted") {
					let notification = new Notification(title, {body: message });
				}
			});
		}

	}

	play_notification(force: boolean = false){

		if ( (!force) && (!this.notify_sound) ) return;

		this.audio.play();

	}

	loop_sound(visitor: Visitor){

		if (!this.keep_ringing) return;

		combineLatest([
			interval(6000),
			this.store.select(selectVisitorById(visitor.id))
		]).pipe(
			takeWhile( ([counter, visitor]) => visitor != null && visitor.is_attended == false && this.keep_ringing)
		).subscribe(([counter, visitor]) => {
			this.play_notification();
		});

	}

	flash_notification(message: string = "New Message", force: boolean = false){

		if ( (!force) && (!this.notify_flash) ) return;

		var original_title = this.titleService.getTitle();
		timer(0, 1000).pipe(take(4)).subscribe(s => {

			if (s % 2 == 0) {
				// 0, 2
				this.titleService.setTitle(message);
			} else {
				// 1, 3
				this.titleService.setTitle(original_title);
			}

		});

	}

}
