import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { AppState } from '../../core/core.state';
import { environment } from '../../../environments/environment';
import { Settings, SettingsResponse} from '../../models/settings.model';
import { Injectable } from '@angular/core';
import { FacebookResponse, Organization, OrganizationResponse, Property, PropertyResponse, TagsResponse, User, UserResponse } from '../../models';
import * as fromModels from "../../models/index";


const API_URL = environment.apiUrl;

@Injectable()
export class SettingsService {

    constructor(
      private http: HttpClient, 
      private store: Store<AppState> ) {
    }

    public getSettings(user_id: string) : Observable<SettingsResponse>  {
        return this.http.get<SettingsResponse>(`${API_URL}/api/settings/${user_id}`);
    }

    public saveSettings(settings: Settings, user_id: string) : Observable<SettingsResponse>  {
      return this.http.post<SettingsResponse>(`${API_URL}/api/settings/${user_id}`, settings);
    }

    public saveOrg(org: Organization) : Observable<OrganizationResponse>  {
      return this.http.put<OrganizationResponse>(`${API_URL}/api/settings/organizations/${org.id}`, org);
    }

    public saveProperty(property: Property, organization_id: string) : Observable<PropertyResponse>  {
      if (property.id){
        return this.http.put<PropertyResponse>(`${API_URL}/api/settings/properties/${property.id}?organization_id=${organization_id}`, property);
      } else {
        return this.http.post<PropertyResponse>(`${API_URL}/api/settings/properties?organization_id=${organization_id}`, property);
      }
    }

    public deleteProperty(property_id: number) : Observable<fromModels.Response>  {
      return this.http.delete<fromModels.Response>(`${API_URL}/api/settings/properties/${property_id}`);
    }

    public addMember(user: User, organization_id: string) : Observable<UserResponse>  {
      if (user.id){
        // it's an edit
        return this.http.put<UserResponse>(`${API_URL}/api/users/member/${user.id}?organization_id=${organization_id}`, user);
      } else {
        // it's a create
        return this.http.post<UserResponse>(`${API_URL}/api/users/member?organization_id=${organization_id}`, user);
      }
    }

    public deleteMember(user_id: string, organization_id: string) : Observable<fromModels.Response>  {
      return this.http.delete<fromModels.Response>(`${API_URL}/api/users/${user_id}?organization_id=${organization_id}`);
    }

    public saveNotifications(settings: Settings, organization_id: string) : Observable<SettingsResponse>  {
      return this.http.post<SettingsResponse>(`${API_URL}/api/settings/notifications?organization_id=${organization_id}`, settings);
    }

    public saveTags(tags: fromModels.Tag[], organization_id: string) : Observable<TagsResponse>  {
      return this.http.post<TagsResponse>(`${API_URL}/api/settings/tags?organization_id=${organization_id}`, tags);
    }

    public saveTicketsTags(tags: fromModels.Tag[], organization_id: string) : Observable<TagsResponse>  {
      return this.http.post<TagsResponse>(`${API_URL}/api/settings/tickets_tags?organization_id=${organization_id}`, tags);
    }

    public connectFacebookPage(token: string, user_id: string, property_id: number){
      return this.http.post<FacebookResponse>(`${API_URL}/api/externalmessenger/connectpage`, {
        access_token: token,
        user_id: user_id,
        property_id: property_id
      } );
    }

    public unlinkFacebookPage(page_id: string){
      return this.http.delete<FacebookResponse>(`${API_URL}/api/externalmessenger/unlinkpage?page_id=${page_id}`);
    }

    public uniqueEmailAlias(email_alias: string, property_id: number){
     
      if (!property_id) property_id = 0;
      
      return this.http.get<string>(`${API_URL}/api/settings/unique_emailalias?email=${email_alias}&property_id=${property_id}`);
    }

    public spfExists(url: string){      
      var eurl = encodeURIComponent(url);
      return this.http.get<string>(`${API_URL}/api/tickets/check_spf?email=${eurl}`);
    }

}