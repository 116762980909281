import { Action, props, createAction } from '@ngrx/store';
import { Subscription } from '../../billing/models/subscription.model';
import { Organization } from '../../models/organization.model';
import { Settings } from '../../models/settings.model';
import { User } from '../../models/user.model';
import { Tag } from 'src/app/models';


export const AllOrganizationsRequested = createAction(
    '[Settings] All Organizations Requested',
    props<{user_id: string}>(),
)

export const AllOrganizationsLoaded = createAction(
    '[Settings] All Organizations Loaded',
    props<{organizations: Organization[]}>(),
)

export const AllMembersRequested = createAction(
    '[Settings] All Members Requested',
    props<{user_id: string}>(),
)

export const AllMembersLoaded = createAction(
    '[Settings] All Members Loaded',
    props<{members: User[]}>(),
)

export const AllSettingsRequested = createAction(
    '[Settings] All Settings Requested'
)

export const AllSettingsLoaded = createAction(
    '[Settings] All Settings Loaded',
    props<{ settings: Settings }>(),
)

export const SwitchPlanSuccess = createAction(
    '[Settings] Billing Switch Plan Completed Successfully',
    props<{sub: Subscription}>(),
)




/* Member Related Actions */

export const MemberSelected = createAction(
    '[Settings] Member Selected',
    props<{ member_id: number }>(),
)

export const MemberSave = createAction(
    '[Settings] Member Save',
    props<{ user: User }>(),
)

export const MemberSaveSuccess = createAction(
    '[Settings] Member Successfully Saved',
    props<{ user: User, message: string }>(),
)


export const MemberSaveFailed = createAction(
    '[Settings] Member Save Failed',
    props<{message: string;}>(),
)

export const MemberDelete = createAction(
    '[Settings] Member Delete',
    props<{ member_id: string }>(),
)

export const MemberDeleteSuccess = createAction(
    '[Settings] Member Delete Success',
    props<{ member_id: string }>(),
)

export const NotificationsSaveRequest = createAction(
    '[Settings] Notifications Save Request',
    props<{settings: Settings;}>(),
)

export const NotificationsSaveSuccess = createAction(
    '[Settings] Notifications Save Success',
    props<{settings: Settings;}>(),
)

export const NotificationsSaveFailed = createAction(
    '[Settings] Notifications Save Failed',
    props<{message: string;}>(),
)

export const NotificationsSaveLoading = createAction(
    '[Settings] Notifications Save Loading',
    props< {isLoading: boolean ;}>(),
)

/**
 * Contact Tags
 */

export const TagsSaveRequest = createAction(
    '[Settings] Tags Save Request',
    props<{tags: Tag[];}>(),
)

export const TagsSaveSuccess = createAction(
    '[Settings] Tags Save Success',
    props<{tags: Tag[];}>(),
)

export const TagsSaveFailed = createAction(
    '[Settings] Tags Save Failed',
    props<{message: string;}>(),
)

/**
 * Tickets Tags
 */

export const TicketsTagsSaveRequest = createAction(
    '[Settings] Tickets Tags Save Request',
    props<{tags: Tag[];}>(),
)

export const TicketsTagsSaveSuccess = createAction(
    '[Settings] Tickets Tags Save Success',
    props<{tags: Tag[];}>(),
)

export const TicketsTagsSaveFailed = createAction(
    '[Settings] Tickets Tags Save Failed',
    props<{message: string;}>(),
)

/**
 * Organization
 */

export const OrgSaveRequest = createAction(
    '[Settings] Organization Save Request',
    props<{ org: Organization }>(),
)

export const OrgSaveSuccess = createAction(
    '[Settings] Organization Save Success',
    props<{ org: Organization  }>(),
)


export const OrgSaveFailed = createAction(
    '[Settings] Organization Save Failed',
    props<{message: string;}>(),
)